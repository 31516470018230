<template>
    <LiefengContent>
        <template #title>{{ menuCodeObj.menuName || "社区培训后情况统计" }}</template>
        <template #toolsbarRight>
            <Button :disabled="loading" style="margin: 0" :type="!orgOnlineStatus ? 'primary' : ''" @click="checkStatus('')">全部示范社区</Button>
            <Button :disabled="loading" style="margin: 0" :type="orgOnlineStatus == 1 ? 'primary' : ''" @click="checkStatus(1)">市级示范社区</Button>
            <Button :disabled="loading" style="margin: 0" :type="orgOnlineStatus == 2 ? 'primary' : ''" @click="checkStatus(2)">区级试用社区</Button>
            <Button :disabled="loading" style="margin: 0" :type="orgOnlineStatus == 3 ? 'primary' : ''" @click="checkStatus(3)">街镇级试用社区</Button>

             <Select :disabled="loading" v-model="queryCommunityType" style="width: 200px;margin:0 20px"  @on-change="changeQuery">
                        <Option value="">全部</Option>
                        <Option :value="1">居民名册</Option>
                        <Option :value="2">网格党支部</Option>
                        <Option :value="3">网格党支部小组</Option>
                        <Option :value="4">十五分钟生活圈</Option>
                        <Option :value="5">已开始发布信息</Option>
             </Select>
             <Button :disabled="loading" type="primary" style="margin:0 20px" @click="getList">查询</Button>

            <Button :disabled="loading" :type="level == 3 ? 'info' : ''" @click="changeLevel(3)" style="margin: 0 0 0 50px">按区</Button>
            <Button :disabled="loading" :type="level == 4 ? 'info' : ''" @click="changeLevel(4)" style="margin: 0">按街道</Button>
            <Button :disabled="loading" :type="level == 5 ? 'info' : ''" @click="changeLevel(5)" style="margin: 0">按社区</Button>

            <Button type="error" style="margin: 0 0 0 50px" @click="Export">导出报表</Button>
        </template>
        <template #contentArea>
            <Form :label-colon="true" :inline="true" class="search" style="position: relative; margin: 5px 0; display: flex; justify-content: space-between">
                <div style="display: flex; width: 100%">
                    <LiefengCascaderCity
                        v-if="showCascader"
                        :disabled="disabled"
                        :orgLevel="level"
                        @changeCasader="changeCasader"
                        :resetNum="resetNum"
                        :orgCode="4401"
                        :width="200"
                        :isClear="true"
                    ></LiefengCascaderCity>

                    <div style="display: flex; justify-content: space-between; margin-left: auto">
                        <span style="margin-top: 7px; margin-left: 10px">更新时间：{{ nowTime1 + " 00:00:00" || "" }}</span>
                    </div>
                </div>
                <div v-if="level == '5'">
                    <Select v-model="onlineType" style="width: 200px" @on-change="changeSelect">
                        <Option value="">全部</Option>
                        <Option :value="1">市级示范社区</Option>
                        <Option :value="2">区级示范社区</Option>
                        <Option :value="3">街镇级示范社区</Option>
                    </Select>
                </div>
            </Form>

            <LiefengTable
                ref="tab"
                :tableData="tableData"
                :talbeColumns="talbeColumns"
                :loading="loading"
                :fixTable="true"
                :hidePage="isHidePage"
                :curPage="page"
                :total="total"
                :page-size="pageSize"
                :pagesizeOpts="[50, 1000, 2000, 3000]"
                @hadlePageSize="hadlePageSize"
                :showsummary="false"
                :summaryData="summaryData"
                :showIndex="false"
            ></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengCascaderCity from "./children/LiefengCascaderCity"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        LiefengCascaderCity,
    },
    data() {
        return {
            orgOnlineStatus: 1, // 切换的状态
            level: 5, // 切换的级别
            columnName: 0, // 栏目数
            nowTime1: "", // 当前时间
            resetNum: 1, // 是否清空当前社区选择

            tableData: [],
            talbeColumns: [],
            loading: false,
            menuCodeObj: {},

            cascaderList: [],

            // 栏目点击参数

            blankStatus: false,
            bTableData: [],
            bTalbeColumns: [
                {
                    title: "栏目路径",
                    key: "columnName",
                    minWidth: 400,
                    align: "center",
                },
                {
                    title: "栏目编码",
                    key: "columnCode",
                    minWidth: 80,
                    align: "center",
                },
            ],
            bLoading: false,
            nameUrl: "",
            clumnNum: 0,
            nowTime2: "",

            onlineType: "",

            disabled: false, // 是否禁用选择器
            isHidePage: false,

            page: 1,
            pageSize: 50,
            total: 0,

            allTableData: [], //暂存全部信息
            showCascader: true,

            summaryData: {},

            allTableData:[],
            lastMsg:{},
            queryCommunityType:''
        }
    },
    async created() {
        this.nowTime1 = this.$core.formatDate(new Date(new Date().getTime()), "yyyy-MM-dd")
        await this.getByMenuCode()
        await this.getList()
    },
    methods: {
        // 改变分页
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.tableData = []
            if(val.page == 1){
                this.tableData = JSON.parse(JSON.stringify(this.allTableData)).slice(0, this.page * this.pageSize)
            }else{
                this.tableData = JSON.parse(JSON.stringify(this.allTableData)).slice((this.page * this.pageSize) - (this.pageSize), this.page * this.pageSize)
            }
            if(this.level == '5'){
                // if(this.page != 1){
                    this.tableData.push(this.lastMsg)
                // }
            }
        },
        changeQuery(val){
            this.queryCommunityType = val
        },
        // 改变示范社区东西
        changeSelect(val) {
            if (val == undefined) return
            this.getList()
        },
        // 改变选择社区组件
        changeCasader(val) {
            this.cascaderList = val
            this.onlineType = ""
            if (!val.length) {
                this.resetNum++
                this.getList()
            }
            if (this.level == "3") {
                if (val.length == 1) this.getList()
            } else if (this.level == "4") {
                if (val.length == 2) this.getList()
            } else if (this.level == "5") {
                if (val.length == 3) this.getList()
            }
        },
        async checkStatus(val) {
            if (this.orgOnlineStatus == val) return
            this.orgOnlineStatus = val
            await this.getList()
        },
        async changeLevel(val) {
            if (this.level == val) {
                return
            }
            this.level = val
            this.showCascader = false
            this.$nextTick(() => {
                this.showCascader = true
            })
            this.isHidePage = true
            if (this.level == "5") this.isHidePage = false
            this.resetNum++
            this.cascaderList = []
            await this.getList()
        },
        async changeLevel2(val) {
            if (this.level == val) {
                return
            }
            this.level = val
            this.showCascader = false
            this.$nextTick(() => {
                this.showCascader = true
            })
            this.isHidePage = true
            if (this.level == "5") this.isHidePage = false
            this.resetNum++
            await this.getList()
        },

        //获取列表
        async getList(val) {
            let arr1 = []
            let arr2 = []
            this.disabled = true
            if (this.orgOnlineStatus != 2 && this.level != 5) {
                if (this.level == 3) {
                    arr1 = [
                        {
                            title: "序号",
                            key: "index",
                            align: "center",
                            width: 100,
                        },
                        {
                            title: "区",
                            key: "zoneName",
                            align: "center",
                            width: 100,
                            render: (h, params) => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            color: this.level == 3 ? "blue" : "#000",
                                            cursor: this.level == 3 ? "pointer" : "auto",
                                            textDecoration: this.level == 3 ? "underline" : "none",
                                        },
                                        on: {
                                            click: async () => {
                                                if (this.level == 3) {
                                                    this.cascaderList = [params.row.zoneCode]
                                                    this.changeLevel2(4)
                                                }
                                            },
                                        },
                                    },
                                    params.row.zoneName
                                )
                            },
                        },
                    ]
                } else if (this.level == 4) {
                    arr1 = [
                        {
                            title: "序号",
                            key: "index",
                            align: "center",
                            width: 100,
                        },
                        {
                            title: "区",
                            key: "zoneName",
                            align: "center",
                            width: 100,
                        },
                        {
                            title: "街道",
                            key: "streetName",
                            align: "center",
                            width: 100,
                            render: (h, params) => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            color: this.level == 4 ? "blue" : "#000",
                                            cursor: this.level == 4 ? "pointer" : "auto",
                                            textDecoration: this.level == 4 ? "underline" : "none",
                                        },
                                        on: {
                                            click: async () => {
                                                if (this.level == 4) {
                                                    this.cascaderList = [params.row.zoneCode, params.row.streetCode]
                                                    this.changeLevel2(5)
                                                }
                                            },
                                        },
                                    },
                                    params.row.streetName
                                )
                            },
                        },
                    ]
                }
                arr2 = [
                    {
                        title: "已导入居民名册社区数",
                        key: "residentsNum",
                        align: "center",
                        minWidth: 100,
                    },
                    {
                        title: "已导入网格党支部社区数",
                        key: "partyNum",
                        align: "center",
                        minWidth: 100,
                    },
                    {
                        title: "已导入网格党支部小组社区数",
                        key: "groupNum",
                        align: "center",
                        minWidth: 100,
                    },
                    {
                        title: "已有十五分钟生活圈社区数",
                        key: "lifeCircleNum",
                        align: "center",
                        minWidth: 100,
                    },
                    {
                        title: "已开始发布信息社区数",
                        key: "pubInfoOrgNum",
                        align: "center",
                        minWidth: 100,
                    },
                ]
            } else if (this.orgOnlineStatus == 2 && this.level != 5) {
                if (this.level == 3) {
                    arr1 = [
                        {
                            title: "序号",
                            key: "index",
                            align: "center",
                            width: 100,
                        },
                        {
                            title: "区",
                            key: "zoneName",
                            align: "center",
                            width: 100,
                            render: (h, params) => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            color: this.level == 3 ? "blue" : "#000",
                                            cursor: this.level == 3 ? "pointer" : "auto",
                                            textDecoration: this.level == 3 ? "underline" : "none",
                                        },
                                        on: {
                                            click: async () => {
                                                if (this.level == 3) {
                                                    this.cascaderList = [params.row.zoneCode]
                                                    this.changeLevel2(4)
                                                }
                                            },
                                        },
                                    },
                                    params.row.zoneName
                                )
                            },
                        },
                    ]
                    arr2 = [
                        {
                            title: "已导入居民名册社区数",
                            key: "residentsNum",
                            align: "center",
                            minWidth: 100,
                        },
                        {
                            title: "已导入网格党支部社区数",
                            key: "partyNum",
                            align: "center",
                            minWidth: 100,
                        },
                        {
                            title: "已导入网格党支部小组社区数",
                            key: "groupNum",
                            align: "center",
                            minWidth: 100,
                        },
                        {
                            title: "已有十五分钟生活圈社区数",
                            key: "lifeCircleNum",
                            align: "center",
                            minWidth: 100,
                        },
                        {
                            title: "已开始发布信息社区数",
                            key: "pubInfoOrgNum",
                            align: "center",
                            minWidth: 100,
                        },
                    ]
                } else if (this.level == 4) {
                    arr1 = [
                        {
                            title: "序号",
                            key: "index",
                            align: "center",
                            width: 100,
                        },
                        {
                            title: "区",
                            key: "zoneName",
                            align: "center",
                            width: 100,
                        },
                        {
                            title: "街道",
                            key: "streetName",
                            align: "center",
                            width: 100,
                            render: (h, params) => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            color: this.level == 4 ? "blue" : "#000",
                                            cursor: this.level == 4 ? "pointer" : "auto",
                                            textDecoration: this.level == 4 ? "underline" : "none",
                                        },
                                        on: {
                                            click: async () => {
                                                if (this.level == 4) {
                                                    this.cascaderList = [params.row.zoneCode, params.row.streetCode]
                                                    this.changeLevel2(5)
                                                }
                                            },
                                        },
                                    },
                                    params.row.streetName
                                )
                            },
                        },
                    ]
                }
            } else if (this.level == 5) {
                arr1 = [
                    {
                            title: "序号",
                            key: "index",
                            align: "center",
                            width: 100,
                        },
                    {
                        title: "区",
                        key: "zoneName",
                        align: "center",
                        width: 100,
                    },
                    {
                        title: "街道",
                        key: "streetName",
                        align: "center",
                        width: 100,
                    },
                    {
                        title: "社区",
                        key: "orgName",
                        align: "center",
                        width: 100,
                    },
                ]
                arr2 = [
                    {
                        title: "已导入居民名册社区数",
                        key: "residentsNum",
                        align: "center",
                        minWidth: 100,
                    },
                    {
                        title: "已导入网格党支部社区数",
                        key: "partyNum",
                        align: "center",
                        minWidth: 100,
                    },
                    {
                        title: "已导入网格党支部小组社区数",
                        key: "groupNum",
                        align: "center",
                        minWidth: 100,
                    },
                    {
                        title: "已有十五分钟生活圈社区数",
                        key: "lifeCircleNum",
                        align: "center",
                        minWidth: 100,
                    },
                    {
                        title: "社区发布信息条数",
                        key: "pubInfoNum",
                        align: "center",
                        minWidth: 100,
                    },
                ]
            }
            this.talbeColumns = [...arr1, ...arr2]
            this.disabled = false
            this.loading = true
            let res = await this.$get("/gateway/api/symanage/pc/portal/statCommunityTraining", {
                zoneCode: this.cascaderList && this.cascaderList.length ? this.cascaderList[0] : "", //区
                streetCode: this.cascaderList && this.cascaderList.length ? this.cascaderList[1] : "", // 街道
                communityCode: this.cascaderList && this.cascaderList.length ? this.cascaderList[2] : "", // 社区
                onlineStatus: this.orgOnlineStatus,
                level: this.level,
                onlineType: this.onlineType,
                queryCommunityType:this.queryCommunityType
            })
            if (res.code == 200) {
                this.loading = false
                this.tableData = res.dataList.map((item,index) =>{
                    return{
                        ...item,
                        index:index+1
                    }
                })

                let residentsNum = 0,
                    partyNum = 0,
                    groupNum = 0,
                    lifeCircleNum = 0,
                    pubInfoOrgNum = 0,
                    pubInfoNum = 0
                this.summaryData = {}
                var sums = {}
                this.tableData.map(item => {
                    residentsNum = residentsNum + item.residentsNum
                    partyNum = partyNum + item.partyNum
                    groupNum = groupNum + item.groupNum
                    lifeCircleNum = lifeCircleNum + item.lifeCircleNum
                    pubInfoOrgNum = pubInfoOrgNum + item.pubInfoOrgNum
                    pubInfoNum = pubInfoNum + item.pubInfoNum
                })
                this.allTableData = this.tableData
                if (this.level == 5) {
                    this.total = this.tableData.length
                    this.tableData = JSON.parse(JSON.stringify(this.allTableData)).splice(0, 50)
                }
                sums = {
                    index:'合计',
                    zoneName:'',
                    streetName:'',
                    orgName:'',
                    residentsNum:!residentsNum || isNaN(residentsNum) ? 0 : residentsNum || 0,
                    partyNum:!partyNum || isNaN(partyNum) ? 0 : partyNum || 0,
                    groupNum:!groupNum || isNaN(groupNum) ? 0 : groupNum || 0,
                    lifeCircleNum: !lifeCircleNum || isNaN(lifeCircleNum) ? 0 : lifeCircleNum || 0,
                    pubInfoOrgNum: !pubInfoOrgNum || isNaN(pubInfoOrgNum) ? 0 : pubInfoOrgNum || 0,
                    pubInfoNum:!pubInfoNum || isNaN(pubInfoNum) ? 0 : pubInfoNum || 0,
                }
                this.lastMsg = sums
                if(this.tableData && this.tableData.length) this.tableData.push(sums)
            } else {
                this.loading = false
                this.$Message.error({
                    content: res.decs,
                    background: true,
                })
            }
        },
        // 获取columnCode
        async getByMenuCode() {
            await this.$get("/old/api/pc/menu/selectMenuByMenuCode", {
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                menuCode: this.$core.getUrlParam("menuId"),
            }).then(res => {
                if (res.code == 200 && res.data) {
                    this.menuCodeObj = res.data
                }
            })
        },
        //导出报表
        Export() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "是否导出社区培训后情况统计",
                onOk: () => {
                    // this.$refs.tab.$refs.lftable.exportCsv({
                    //     filename: "社区培训后情况统计",
                    // })
                    var tHeader = []
                    var filterVal = []
                    var tableData = []
                    tableData = JSON.parse(JSON.stringify(this.allTableData))
                    if(this.level == '3'){
                         tHeader = ["区", "已导入居民名册社区数	", "已导入网格党支部社区数", "已导入网格党支部小组社区数","已有十五分钟生活圈社区数","已开始发布信息社区数"]
                         filterVal = ["zoneName", "residentsNum", "partyNum", "groupNum","lifeCircleNum","pubInfoOrgNum"]
                    }else if(this.level == '4'){
                         tHeader = ["区","街道", "已导入居民名册社区数	", "已导入网格党支部社区数", "已导入网格党支部小组社区数","已有十五分钟生活圈社区数","已开始发布信息社区数"]
                         filterVal = ["zoneName","streetName", "residentsNum", "partyNum", "groupNum","lifeCircleNum","pubInfoOrgNum"]
                    }else if(this.level == '5'){
                         tHeader = ["区", "街道", "社区" ,"已导入居民名册社区数	", "已导入网格党支部社区数", "已导入网格党支部小组社区数","已有十五分钟生活圈社区数","社区发布信息条数"]
                         filterVal = ["zoneName","streetName","orgName", "residentsNum", "partyNum", "groupNum","lifeCircleNum","pubInfoNum"]
                         tableData.push(this.lastMsg)
                    }
                     
                    
                    this.$core.exportExcel(tHeader, filterVal, tableData, "社区培训后情况统计")
                },
            })
        },

        changeBlankStatus(val) {
            this.blankStatus = val
        },
    },
}
</script>
    
<style scoped lang='less'>
/deep/#title {
    justify-content: space-between;
    display: flex;
    padding: 10px;
}
.readnum {
    display: inline;
    margin-left: 20px;
    font-size: 14px;
    font-weight: 700;
    li {
        display: inline;
        margin-left: 15px;
    }
    .click {
        text-decoration: underline;
        cursor: pointer;
    }
}
.readNum {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 20px;
    li {
        display: inline;
        margin-left: 15px;
    }
}
.ivu-form-item {
    margin-bottom: 5px;
    margin-right: 0px;
}
/deep/.ivu-table-sort {
    width: 26px;
    height: 13px;
    i {
        font-size: 27px;
    }
}
</style>